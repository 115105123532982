/**
 * Show error for discount code form
 * @param message
 */
function showCodeError(message) {
    var form = $(".code-form"), status = $(".code-status");
    form.fadeOut(200, function () {
        status.html(message).fadeIn(200);
        setTimeout(showCodeDefault, 2000);
    });
}

/**
 * Show success message for discount code form
 * @param message
 */
function showCodeSuccess(message) {
    /*var code = $("#cart .code");
     code.removeClass("error").find("input[type=submit]").hide();
     code.find("span").html("Your code applied").fadeIn(800);
     $(".codeNotice").fadeOut(400, function () {
     $(this).html(message).fadeIn(400);
     });*/
}

/**
 * Show default message for discount code form
 */
function showCodeDefault() {
    var notice = $(".codeNotice"), form = $(".code-form"), status = $(".code-status");
    if (notice.length) {
        if (notice.data("default")) {
            notice.html(notice.data("default"));
        }
    }
    status.fadeOut(200, function () {
        status.html("");
        form.fadeIn(200);
    })
}

/**
 * Check if amount is not empty
 * @returns {boolean}
 */
function isEmptyQnt() {
    var items = collectItemQnt();
    if (!items) {
        return true;
    }
    for (var i in items) {
        if (items[i] == 0) {
            return true;
        }
    }
    return false;
}

/**
 * Find amount for each design
 * @returns {{}}
 */
function collectItemQnt() {
    var qnt = "#cart .qnt", items = {};
    $(qnt).each(function () {
        var id = $(this).data("design");
        var val = parseInt($(this).val()) || 0;
        if (items[id] == undefined) {
            items[id] = val;
        } else {
            items[id] += val;
        }
    });
    return items;
}

/**
 * Update user cart
 * @param data
 */
function updateCart(data) {
    $("#cart").html(data);
    calcGroupsAmountTotal()
}

$(document).ready(function () {
    var cartRoot = ".cart", cart = "#cart", code = cart + " .code", general = cart + " .general", qnt = cart + " .qnt";

    // Submit changes to the cart and update
    $(document).on("submit", general, function (e) {
        e.preventDefault();
        $.get("/cart/update/", $(this).serialize(), updateCart);
    });

    // Mark notice
    if ($(cartRoot + " .price.c-red").length) {
        setTimeout(function () {
            $(cartRoot + " .price.c-red").each(function () {
                $(this).hasClass("caption") ? $(this).fadeOut() : $(this).removeClass("c-red");
            });
        }, 5000);
    }

    // Apply discount code
    $(document).on("submit", code, function (e) {
        e.preventDefault();
        $.post("/cart/code/", $(this).serialize(), function (data) {
            if (data.error) {
                setTimeout(function () {
                    $.get("/cart/update/", $(general).serialize(), updateCart);
                }, 2000);
                return showCodeError(data.error);
            }
            if (data.success) {
                $.get("/cart/update/", $(general).serialize(), updateCart);
            } else {
                return showCodeDefault();
            }
        }, "json");
    });

    // Change code to default when typing new text
    $(document).on("keyup", code + " input[type=text]", showCodeDefault);

    // Submit cart form when quantity was changed
    $(document).on("change", qnt, function () {
        var min = $(this).prop('min');
        if (min != undefined) {
            if (parseInt($(this).val()) < parseInt(min)) {
                $(this).val(min);
            }
        }
        return submit(this);
    });

    // Remove cart item and update cart
    $(document).on("click", cart + " .remove", function () {
        if (!confirm('Are you sure?')) {
            return false;
        }
        $.get("/cart/remove/", {id: $(this).data("id")}, updateCart);
    });

    // Check cart item available
    $(document).on("click", cart + " .checkItemQnt", function (e) {
        e.preventDefault();
        if (isEmptyQnt()) {
            return popup('#emptyCartItems');
        }
        return window.location.href = $(this).attr("href");
    });

    // Remove cart item where quantity is empty
    $(document).on("click", cart + " #removeEmptyCartItems", function () {
        var items = collectItemQnt();
        if (!items) {
            return fadeOut(".overlay", "#emptyCartItems");
        }
        var list = [];
        for (var id in items) {
            if (items[id] != 0) continue;
            if (id === 'length' || !items.hasOwnProperty(id)) continue;
            list.push(id);
        }
        if (list.length) {
            $.get("/cart/remove-bunch/", {id: list.join(',')}, updateCart);
        }
        return fadeOut(".overlay", "#emptyCartItems");
    });

    // Fill form using user default address
    $("#defaultAddress").change(function () {
        fillAddress($(this).val());
    });

    // Copy shipping address to the payment address form
    $("input[name=asShipping]").change(function () {
        if (address === undefined) {
            return
        }

        const shouldClear = !$(this).is(":checked")
        for (const i in address) {
            const el = $('[name=' + i + ']')
            if (el.length) {
                const value = i === 'stateId' ? states[address[i]] : address[i]

                el.val(shouldClear ? '' : value)
            }
        }

        $('#card-billing-address-state').trigger('change')
    });

    // Switch between payment forms
    $(".paymentSwitch").change(function () {
        const self = $(this);
        const id = self.attr("id");
        const submitButton = $('#card-field-submit-button')

        $('.payment-boxes').hide();

        $(`#${id}_box`).show();

        id === 'paypal' ? submitButton.hide() : submitButton.show()
    });

    // Show additional information for the shipping types
    $(".shipping-types input:radio").click(function () {
        $(".shipping-types label").removeClass("selected");
        $(this).closest("label").addClass("selected");
        $(".estimate").hide();
        $("#estimate_" + $(this).val()).show();
    });

    // Show sign type form
    $("input[name=signType]").click(function () {
        $(".sign-type div").hide();
        $(this).closest(".sign-type").find("div").show();
        var label = $(this).closest(".sign-type").find("input[type=submit]").val();
        if ($("legend span").length) {
            $("legend span").html(label);
        }
    });

    if ($("#looksValidation").length) {
        slideTo("#looksValidation");
    }
    $(".valid-address label").click(function () {
        $(".valid-address div").removeClass("active");
        $(this).closest("div").addClass("active");
    });

    $(".checkout form").submit(function () {
        if ($(this).valid()) {
            var submit = $(this).find("input:submit");
            if (submit.length) {
                submit.attr('disabled', 'disabled');
            }
        }
    });

    $('#js-complete-order').on('click', function (e) {
        e.preventDefault();
        const button = e.target

        button.disabled = true
        button.innerText = 'Processing...';

        const csrf = {
            [$('meta[name="csrf-param"]').attr('content')]: $('meta[name="csrf-token"]').attr('content')
        }

        $.post("/checkout/approve/", {
            ...csrf,
            subscribe: $('input[name=subscribe]').is(':checked') ? 1 : 0
        }).then((res) => {
            if (res.error) {
                button.disabled = false;
                button.innerText = 'Complete Order';

                return error(res.error);
            }

            window.location.assign('/checkout/receipt/?order=' + res.order);
        }).catch((err) => {
            button.disabled = false;
            button.innerText = 'Complete Order';

            error('Error processing order payment. Please try again.');
            console.error(err);
        });
    })

    $(document).on("change", ".popup.change-options select", function (e) {
        var options = [], selects = $(this).closest('.product-constructor-features').find('select');
        selects.each(function (i, obj) {
            options.push($(obj).val())
        });
        selects.each(function (i, obj) {
            var defaultOption = null, shouldSetDefault = false;
            $.each(obj.options, function (j, option) {
                var id = option.value, parent = option.dataset.parent;
                if (parent === '') {
                    defaultOption = defaultOption || option;
                    return;
                }
                if (options.indexOf(parent) !== -1) {
                    defaultOption = defaultOption || option;
                    return option.style.display = 'inline'
                }
                option.style.display = 'none';
                if (options.indexOf(id) !== -1) {
                    shouldSetDefault = true;
                }
            });
            if (shouldSetDefault && defaultOption) {
                $(obj).val(defaultOption.value).trigger('change');
            }

        });
    })

    function initPlacesAutocomplete(elements) {
        const {
            address1,
            city,
            state,
            zip,
            zip4
        } = elements;

        function applySelectedPlace(place) {
            const types = {
                'street_number': address1,
                'route': address1,
                'locality': city,
                'administrative_area_level_1': state,
                'postal_code': zip,
                'postal_code_suffix': zip4
            }

            $.getJSON("/ajax/places/?id=" + place.place_id).done(function (data) {
                Object.values(elements).forEach((el) => el.val('').trigger('change'));

                if (data.status !== 'OK') {
                    return;
                }

                for (const component of data.result.address_components) {
                    for (const type of component.types) {
                        if (!types[type]) {
                            continue;
                        }

                        const input = $(types[type]);
                        if (type === 'administrative_area_level_1') {
                            const option = input.find('option:contains("' + component.short_name + '")');
                            if (option.length) {
                                input.val(option.val()).trigger('change');
                            }
                            continue;
                        }
                        input.val([input.val(), component.long_name].join(' ').trim());
                    }
                }
            });
        }

        const hitsContainer = $("<div/>").addClass("places-container");

        address1.after(hitsContainer);

        address1.on("keyup", debounce(function (e) {
            const value = e.target.value;

            if (value.length < 3) {
                hitsContainer.hide();
                return;
            }

            function showHits(data) {
                hitsContainer.empty();

                if (!data.predictions || !data.predictions.length) {
                    return hitsContainer.hide();
                }

                data.predictions.forEach(function (prediction) {
                    const button = $("<button type='button'/>")
                        .text(prediction.description)
                        .on("click", function () {
                            applySelectedPlace(prediction);
                            hitsContainer.hide();
                        });

                    hitsContainer.append(button);
                });

                hitsContainer.show();
            }

            $.getJSON("/ajax/places/?query=" + value).done(showHits);

        }, 200));

        document.addEventListener('click', function (e) {
            if (!hitsContainer.is(e.target) && hitsContainer.has(e.target).length === 0 && !$(e.target).hasClass('places-container')) {
                hitsContainer.hide();
            }
        });
    }

    const shippingAddress1 = $('#address-address1');
    if (shippingAddress1.length) {
        initPlacesAutocomplete({
            address1: shippingAddress1,
            address2: $('#address-address2'),
            city: $('#address-city'),
            state: $('#address-stateid'),
            zip: $('#address-zip'),
            zip4: $('#address-zip4')
        })
    }

});
